import dayjs from "@/plugins/dayjs";
import * as Sentry from "@sentry/vue";
import { request, talentRequest } from "@/services/api";
import {
  MENTOR_ROLE,
  PARTICIPANT_ROLE,
  ADULT_CONFIRM_REJECTED,
  ADULT_CONFIRM_APPROVED,
  ADULT_CONFIRM_MODERTION,
} from "@/constants";
import { userSerializer } from "@/utils/user";
import findIndex from "lodash/findIndex";

export const user = {
  namespaced: true,
  state: () => ({
    layout: "default",
    authError: null,
    user: null,
    talentUser: null,
    yandexAccounts: [],
    yandexChecked: false,
    // Guest - если мы попытались авторизовать,
    // и не смогли
    guest: false,
    consentStatuses: {
      [ADULT_CONFIRM_MODERTION]: "moderation",
      [ADULT_CONFIRM_APPROVED]: "accepted",
      [ADULT_CONFIRM_REJECTED]: "rejected",
    },
    consentCheckSuccess: false,
    telegramAccounts: null,
    educations: [],
  }),
  mutations: {
    /**
     * @param {object} state
     * @param {object} payload
     * @param {string} payload.key
     * @param {*} payload.value
     */
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    PATCH_STATE(state, payload) {
      if (!state[payload.key]) {
        state[payload.key] = state[payload.value];
      } else {
        state[payload.key] = { ...state[payload.key], ...payload.value };
      }
    },
    PATCH_USER(state, payload) {
      state.user = { ...state.user, ...payload };
    },
    PATCH_EDUCATION(state, education) {
      if (!state.educations) {
        return;
      }
      const idx = findIndex(state.educations, (n) => n.id === education.id);
      if (idx >= 0) {
        state.educations.splice(idx, 1, {
          ...state.educations[idx],
          ...education,
        });
      }
    },
  },
  getters: {
    user: (state) => {
      const { talentUser } = state;
      if (!talentUser) return state.user;
      const data = {
        ...state.user,
        ...userSerializer(talentUser),
        current_education: talentUser.current_education,
        grade: talentUser.grade,
        adult_confirmed: talentUser.adult_confirmed,
        public_page_enabled: talentUser.public_page_enabled,
      };
      return data;
    },
    age(state) {
      const bday = state.talentUser?.birthday;
      if (!bday) return;
      return dayjs().diff(dayjs(bday, "YYYY-MM-DD"), "year");
    },
    requireConsentFile(state, getters) {
      const { talentUser } = state;
      const age = getters.age;
      if (!talentUser) return false;
      if (age < 14) {
        return talentUser.adult_confirmed === "";
      }
      if (age >= 14 && age < 18) {
        return (
          talentUser.adult_confirmed === "" && talentUser.adult_confirm_comment
        );
      }
      return false;
    },
    consentStatus(state) {
      const { talentUser } = state;
      const status = state.consentStatuses[talentUser?.adult_confirmed];
      if (status) return status;
      return state.consentStatuses[ADULT_CONFIRM_REJECTED];
    },
    isMentor(state) {
      return state.user?.role === MENTOR_ROLE;
    },
    isParticipant(state) {
      return (
        state.user?.role === PARTICIPANT_ROLE && !!state.user?.participant?.id
      );
    },
    ontiUser(state) {
      return state.user;
    },
    yandexId(state) {
      if (!state.user) return;
      if (state.user.participant) {
        return state.user.participant.yandex_id;
      } else {
        return state.user?.yandex_id;
      }
    },
    yandexUser(state, getters) {
      const id = getters.yandexId;
      if (!id) return;
      const acc = state.yandexAccounts?.find((n) => n.uid === String(id));
      if (acc?.email) return acc.email;
      return id;
    },
    requireYandex(state, getters) {
      if (!state.user) return false;
      return !getters.yandexId;
    },
    talentId(state) {
      return state.talentUser?.id;
    },
  },
  actions: {
    async getMe({ commit, dispatch }) {
      try {
        const tUser = await dispatch("getTalentUser");
        const { data } = await request({
          url: "/user/info",
        });
        const uData = {
          ...data,
        };
        if (data) {
          uData.is_adult_confirm_intruder = tUser?.is_adult_confirm_intruder;
          commit("SET_STATE", {
            key: "user",
            value: uData,
          });
          commit("SET_STATE", {
            key: "guest",
            value: false,
          });
        }
        return uData;
      } catch (error) {
        commit("SET_STATE", {
          key: "guest",
          value: true,
        });
        throw error;
      }
    },
    async getTalentUser({ commit, state }, ignoreCache) {
      if (state.talentUser && !ignoreCache) return state.talentUser;
      const { data } = await talentRequest({
        url: "/api/users/me/",
      });
      if (data.id) {
        commit("SET_STATE", {
          key: "talentUser",
          value: data,
        });

        try {
          Sentry.configureScope((scope) =>
            scope.setUser({
              id: data.id,
            })
          );
        } catch (error) {
          console.log("error", error);
          Sentry.configureScope((scope) => scope.setUser(null));
        }
      }
      return data;
    },
    async getYandexAccount({ commit, state }) {
      const { data } = await talentRequest({
        method: "get",
        url: `/api/users/${state.user.talent_id}/social-auths/`,
      });

      const accounts = data.results.filter((n) => {
        return n.provider === "yandex";
      });

      if (accounts.length) {
        commit("SET_STATE", {
          key: "yandexAccounts",
          value: accounts,
        });
        return accounts;
      }
    },
    async updateUserInfo({ commit, state }, params) {
      const { data } = await talentRequest({
        url: `/api/users/${state.talentUser.id}/`,
        method: "patch",
        data: params,
      });
      commit("PATCH_STATE", {
        key: "talentUser",
        value: data,
      });
      return data;
    },
    async getEducation({ state, commit }, ignoreCache = false) {
      if (state.educations?.length && !ignoreCache) return state.educations;
      const { data } = await talentRequest({
        url: `/api/users/${state.talentUser.id}/educations/`,
        method: "GET",
        limit: 40,
        offset: 0,
      });
      if (Array.isArray(data?.results)) {
        commit("SET_STATE", {
          key: "educations",
          value: data?.results,
        });

        return data.results;
      }
      return [];
    },
    async getTelegramAccounts({ state, commit }) {
      if (state.telegramAccounts) {
        return state.telegramAccounts;
      }
      const { data } = await talentRequest({
        method: "get",
        url: `/api/users/${state.user.talent_id}/social-auths/telegram/ `,
      });
      commit("SET_STATE", {
        key: "telegramAccounts",
        value: data?.results,
      });
    },

    async checkYandex({ state, commit, dispatch, getters }) {
      // Если ментор
      const userAcc = getters.isParticipant
        ? state?.user?.participant?.yandex_id
        : state.user?.yandex_id;
      if (!userAcc) {
        // если аккаунт пока не привязан не выкидываем ошибку
        return true;
      }
      function isValid(id) {
        return state.yandexAccounts?.some((n) => +n.uid === id);
      }

      if (state.yandexChecked || state.yandexAccounts?.length) {
        return isValid(userAcc);
      }

      try {
        await dispatch("getYandexAccount");
        const status = isValid(userAcc);
        commit("SET_STATE", {
          key: "yandexChecked",
          value: status,
        });
        return status;
      } catch (error) {
        return false;
      }
    },
  },
};
