import Vue from "vue";
import findIndex from "lodash/findIndex";
import { request, talentRequest, calconApiRequest } from "@/services/api";
import { normalizeByKey } from "@/utils";
import { ProfileService } from "@/services/profile";
import { getEducationBlocks } from "@/services/shared/eductions";
import {
  JUNIOR_ALIAS,
  STUDENT_ALIAS,
  TEAM_STAGE,
  INDIVIDUAL_STAGE,
  FINAL_STAGE,
  TEAM_BUILDING_STAGE,
  TEAM_ASSIGNMENT_FILED_TRACK,
  LOW_SCHOOL_ALIAS,
  PARTICIPANT_ROLE,
} from "@/constants";
import { PROFILE_LABELS } from "@/constants/profile";
import {
  parseUserAchievement,
  mapCalconAchievement,
} from "@/utils/achievements";
import { combinePrivileges } from "@/utils/privileges";
import { userInviteMentorLink } from "@/utils/invites";
import dayjs from "@/plugins/dayjs";

export const participant = {
  namespaced: true,
  state: () => ({
    loading: false,
    inviteLink: null,
    info: {},
    profiles: [],
    track: {},
    mentors: {},
    merch_size: null,
    grade: null,
    prefered_track: null,
    infoFetched: false,
    achievements: [],
    privileges: [],
    navigation: [
      {
        title: "Главная",
        name: "user-index",
        icon: "brand",
      },
      {
        title: "Расписание",
        name: "user-schedule",
        icon: "calendar",
      },
      {
        title: "Наставники",
        name: "user-mentors",
        icon: "whistle",
      },
    ],
    friendInviteLink: null,
    scores: {},
    scoresFetchedAt: null,
    teams: {},
    // Скрывать или показывать активности
    // повышенной сложности (10-11) для учеников 8-9 классов
    hideHighLevelActivity: true,
    // трек участника по заявке
    // необходимы для гибридного трека
    requestTrack: {},
    // Список предметов, из сервиса для
    // проверки работ финала, разложенный
    // по id активности НТО
    worksSubjects: {},
    // Список работ, из сервиса для
    // проверки работ финала, разложенный
    // по id worksSubject
    works: {},
    worksError: "",
    partners: [],
    finalAchievements: {},
    talent_education_id: "",
    edublocks: [],
  }),
  getters: {
    /**
     * Список выбранных профилей и спецпроектов
     * первого этапа
     * @returns {Array}
     */
    selectedList(state) {
      // В итоге я выбираю все уникальные не зареджектиные профили,
      return Object.values(
        state.profiles.reduce((acc, value) => {
          if (!acc[value.profile_id] && !value.rejected) {
            acc[value.profile_id] = {
              ...value,
              failed: value.failed,
            };
          }
          return acc;
        }, {})
      );
    },
    // Карта треков по профилю для гибридного трека
    // т.е. соответствие profile_id: track_id
    // Связь берем только из индивидуального этапа
    hybridProfilesTracks(state) {
      const result = {};
      if (state.track?.alias !== LOW_SCHOOL_ALIAS) return result;

      return state.profiles.reduce((acc, profile) => {
        const preferred = profile.prefered_next_step_track?.id;
        if (profile.stage === INDIVIDUAL_STAGE) {
          acc[profile.profile_id] = preferred || state.track?.id;
        }
        return acc;
      }, result);
    },
    isJunior(state) {
      return state.track?.alias === JUNIOR_ALIAS;
    },
    isStudent(state) {
      return state.track?.alias === STUDENT_ALIAS;
    },
    isHighSchool(state, getters) {
      return state.track && !getters.isStudent && !getters.isJunior;
    },
    profileLimit(state) {
      return state.track?.profile_limits;
    },
    profileLabels(state, getters) {
      if (getters.isJunior) {
        return {
          profile: PROFILE_LABELS.sphere,
          special: PROFILE_LABELS.special,
        };
      }
      return {
        profile: PROFILE_LABELS.profile,
        special: PROFILE_LABELS.special,
      };
    },
    activityScores(state) {
      return state.scores.activity_scores?.reduce((acc, val) => {
        acc[val.activity_id] = val;
        return acc;
      }, {});
    },
    /**
     * Список всех активностей, по всем этапам
     * в выбранных пользователем профилях
     * и спецпроектах
     * @returns {object} - Объект с активностями {<Activity.id>: <Activity>}
     */
    allActivities(state, getters, rootState) {
      const selected = getters.selectedList;
      const profiles = rootState.profile.profiles;
      // Может ли быть повышенный уровень
      if (!selected.length) return {};
      const activities = selected.reduce((acc, value) => {
        const profile = profiles[value.profile_id];
        if (profile?.activities) {
          profile.activities.forEach((activity) => {
            if (!acc[activity.id]) {
              acc[activity.id] = {
                ...activity,
                profiles: [value.profile_id],
                isExtra:
                  activity.stage === INDIVIDUAL_STAGE &&
                  state.track?.alias === LOW_SCHOOL_ALIAS &&
                  activity.title?.includes("10-11"),
              };
            } else {
              acc[activity.id].profiles.push(value.profile_id);
            }
          });
        }
        return acc;
      }, {});
      return activities;
    },
    activitiesByStage: (state, getters, rootState, rootGetters) => (stage) => {
      if (!stage) return [];
      let list = Object.values(getters.allActivities).filter(
        (n) => n.stage === stage && n.is_active
      );
      // Трек по этапу нужно брать из участника,
      // кроме командного этапа для 8-9 класса, т.к. у них
      // трек нужно брать из заявки
      const isLow = state.track?.alias === LOW_SCHOOL_ALIAS;
      if ((stage === INDIVIDUAL_STAGE && !isLow) || !isLow) {
        list = list.filter((activity) =>
          activity.tracks.some((t) => t.id === state.track.id)
        );
      } else if (stage === INDIVIDUAL_STAGE && isLow) {
        // для 8-9 классов, нужно отфильтровать активности, по выбранному треку профиля
        // т.е. активности содержащие трек для 10-11 должны быть отфильтрованы,
        // если пользователь не захотел участвовать в решение задач для старших классов
        list = list.filter((activity) => {
          const isUserTrack = activity.tracks.some((track) => {
            return track.id === state.track.id;
          });

          // если активность не содержит трек юзера
          if (!isUserTrack) return false;

          // Если это не гибридная активность, то вернем ее
          if (!activity.isExtra && isUserTrack) return true;

          // Если это активность для 10-11 классов
          // то нужно проверить, что хотя бы в одном профиле этой активности
          // пользователь сказал что будет решать задачи не своего уровня
          if (activity.isExtra) {
            return activity.profiles.some(
              (profile_id) =>
                getters.hybridProfilesTracks[profile_id] ===
                rootGetters.schoolTrackId
            );
          }
          return false;
        });
      }

      if (stage === TEAM_STAGE) {
        // Нужно пометить командные активности,
        // если у юзера есть команда в этом профиле
        if (!getters.teamStageList.length) return [];
        const tList = getters.teamStageList.map((n) => n.profile_id);
        // отфильтруем только профилям юзера командного этапа
        list = list.filter((n) => {
          return n.profiles.some((pid) => tList.includes(pid));
        });

        // трек по второму этапу для гибридного трека надо брать из заявки
        if (getters.isHybridTrack) {
          list = list.filter((n) => {
            // находим трек профиля по профилям в активности
            const pt = n.profiles.find((p) => getters.teamProfileTracks[p]);
            const pTrack = getters.teamProfileTracks[pt];
            // Возвращаем только те активности, которые соответствуют
            // треку внутри профиля
            return pTrack && n.tracks.some((t) => t.id === pTrack);
          });
        }
        list = list.map((n) => {
          const hasTeam =
            !n.individual_activity &&
            n.profiles?.some((pID) => !!state.teams[pID]);
          return {
            ...n,
            hasTeam,
          };
        });
      }

      if (stage === FINAL_STAGE) {
        if (!getters.finalStageList.length) return [];
        // Для джуниор трека нужно выводить активности
        // финала только если мероприятие финала  - оффлайн
        if (getters.isJunior && getters.juniorFinalEvent?.format !== "online") {
          return [];
        }
        const fList = getters.finalStageList.map((n) => n.profile_id);
        list = list
          .filter((n) => {
            // отфильтруем профили с неотклоненной заявкой
            if (!n.profiles.some((pid) => fList.includes(pid))) {
              return false;
            }
            if (getters.isHybridTrack) {
              // находим трек профиля по профилям в активности
              const pt = n.profiles.find((p) => getters.teamProfileTracks[p]);
              const pTrack = getters.teamProfileTracks[pt] || state.track.id;
              // Возвращаем только те активности, которые соответствуют
              // треку внутри профиля
              return pTrack && n.tracks.some((t) => t.id === pTrack);
            } else {
              return n.tracks.some((t) => t.id === state.track.id);
            }
          })
          .map((item) => {
            return {
              ...item,
              workSubject: state.worksSubjects[item.id],
            };
          });
      }
      return list;
    },
    /**
     * Базовое расписание
     * этапов (общее не по профилям)
     * @returns {Object} - объект с расписанием
     */
    stageSchedule(state, getters, rootState) {
      const schedule = rootState.schedule;
      if (!Array.isArray(schedule)) return {};
      return schedule.reduce((acc, value) => {
        if (!value.profile) {
          acc[value.stage] = value;
        }
        return acc;
      }, {});
    },
    /*
     * Список профилей и спецпроектов по которым
     * участник прошел в командный этап
     */
    teamStageList(state, getters, rootState) {
      const list = state.profiles.filter((p) => {
        return p.stage === TEAM_STAGE && !p.rejected;
      });
      if (!list.length) return [];
      // Нужно понять, доступно ли
      // по расписанию формирование команд
      return list.map((profile) => {
        let allowTeamBuilding = false;
        // смотрим на расписание профиля, на доступность TEAM_BUILDING
        const profileSchedule = rootState.profile.schedule[profile.profile_id];
        const stageState = {
          isStarted: false,
          isEnded: false,
          isActive: false,
        };
        let buildingStage = profileSchedule?.find((stage) => {
          return stage.stage === TEAM_BUILDING_STAGE;
        });
        // если у профиля не задан stage
        // по командообразованию, то смотрим
        // на общее расписание для `TEAM_BUILDING_STAGE`
        if (!buildingStage) {
          buildingStage = rootState.schedule?.find((stage) => {
            return stage.stage === TEAM_BUILDING_STAGE && !stage.profile;
          });
        }
        if (buildingStage) {
          stageState.isStarted =
            !!buildingStage.start_at &&
            dayjs(rootState.syncTimeStamp).isAfter(buildingStage.start_at);
          stageState.isEnded =
            !!buildingStage.end_at &&
            dayjs(rootState.syncTimeStamp).isAfter(buildingStage.end_at);
          stageState.isActive = stageState.isStarted && !stageState.isEnded;

          buildingStage = {
            ...stageState,
            id: buildingStage.id,
            profile_id: buildingStage.profile_id,
            end_at: buildingStage.end_at,
            start_at: buildingStage.start_at,
          };
          // если мы между этими датами, то можно создавать команды
          allowTeamBuilding = buildingStage.isActive;
        }
        return {
          ...profile,
          allowTeamBuilding,
          buildingStage,
        };
      });
    },
    firstStage(state, getters, rootstate) {
      const stage = getters.isStudent ? TEAM_STAGE : INDIVIDUAL_STAGE;
      const result = {
        stage,
        isEnded: false,
        isStarted: false,
      };
      if (rootstate.schedule?.length) {
        const current = rootstate.schedule.find((n) => {
          return n.stage === stage && !n.profile;
        });
        result.end_at = current.end_at;
        result.start_at = current.start_at;
        result.isEnded = dayjs(current.end_at).isBefore(
          dayjs(rootstate.syncTimeStamp)
        );
        result.isStarted = dayjs(current.start_at).isBefore(
          dayjs(rootstate.syncTimeStamp)
        );
      }
      return result;
    },
    secondStage(state, getters, rootState) {
      const stage = getters.isHighSchool ? TEAM_STAGE : FINAL_STAGE;
      const result = {
        stage,
        isEnded: false,
        isStarted: false,
      };
      let current = rootState.schedule?.find((n) => {
        return n.stage === stage && !n.profile;
      });
      if (current) {
        result.end_at = current.end_at;
        result.start_at = current.start_at;
        result.isEnded = dayjs(current.end_at).isBefore(
          dayjs(rootState.syncTimeStamp)
        );
        result.isStarted = dayjs(current.start_at).isBefore(
          dayjs(rootState.syncTimeStamp)
        );
      }
      return result;
    },
    thirdStage(state, getters, rootstate) {
      if (!getters.isHighSchool) return undefined;
      const stage = FINAL_STAGE;
      const result = {
        stage,
        isEnded: false,
        isStarted: false,
      };
      const current = rootstate.schedule?.find((n) => {
        return n.stage === stage && !n.profile;
      });
      if (current) {
        result.end_at = current.end_at;
        result.start_at = current.start_at;
        result.isEnded = dayjs(current.end_at).isBefore(
          dayjs(rootstate.syncTimeStamp)
        );
        result.isStarted = dayjs(current.start_at).isBefore(
          dayjs(rootstate.syncTimeStamp)
        );
      }
      return result;
    },
    teamsAllowedProfiles(state, getters) {
      return getters.teamStageList;
    },
    /**
     * список привилегий участника - для текущего сезона НТО
     */
    privileges(state, getters, rootState) {
      const profiles = rootState.profile.profiles;
      // вернем только те привилегии, которые могут быть использованы для профиля
      // текущего сезона
      return state.privileges.filter((priv) => {
        return (
          priv.profiles?.length > 0 && priv.profiles.some((p) => p in profiles)
        );
      });
    },
    notUsedPrivileges(state, getters) {
      return getters.privileges.filter((n) => {
        // Убираем протухшие привилегии
        if (n.expired_at && dayjs().isAfter(n.expired_at)) {
          return false;
        }
        // возвращаем только привилегии у которых не установлен профиль
        return !n.profile_id;
      });
    },
    /**
     * карта Профиль: трек
     * для командного этапа
     */
    teamProfileTracks(state, getters) {
      const list = getters.teamStageList;
      return list.reduce((acc, value) => {
        let trackId = state.track?.id;
        const reqTrack = state.requestTrack[value.talent_event_request_id];
        if (reqTrack) {
          trackId = reqTrack;
        }
        acc[value.profile_id] = trackId;
        return acc;
      }, {});
    },
    isHybridTrack(state) {
      return [8, 9].includes(state.grade);
    },
    finalStageList(state) {
      return state.profiles.filter((p) => {
        return p.stage === FINAL_STAGE && !p.rejected;
      });
    },
    /**
     * Баллы, за индивидуальный этап,
     * разложенные по профилям
     */
    individualProfilesScores(state, getters, rootState) {
      const scores = state.scores?.profile_scores;
      const tracks = rootState.tracks;
      if (!scores?.length) return {};
      return scores.reduce((acc, score) => {
        if (score.stage === INDIVIDUAL_STAGE && score.score) {
          if (!acc[score.profile_id]) {
            acc[score.profile_id] = [];
          }
          // Добавляем скоры, если трек в скорах равен треку участника,
          // или трек кора равен предпочтительному треку выбранного профиля
          if (
            score.track_id === state.track?.id ||
            (getters.isHybridTrack &&
              getters.hybridProfilesTracks[score.profile_id] === score.track_id)
          ) {
            acc[score.profile_id].push({
              track: tracks[score.track_id]?.title,
              track_id: score.track_id,
              score: score.score,
              updated_at: score.updated_at,
            });
          }
        }
        return acc;
      }, {});
    },
    /**
     * Для 4-клашек есть ряд ограничений в лк
     */
    is4GradeJunior(state, getters) {
      return getters.isJunior && state.grade === 4;
    },
    /**
     * Мероприятие финала для джуниора
     */
    juniorFinalEvent(state, getters, rootState) {
      if (
        !getters.isJunior ||
        getters.is4GradeJunior ||
        !getters.selectedList?.length
      )
        return;
      const eventId = state.profiles?.find((n) => {
        return n.final_event && !n.rejected;
      })?.final_event;
      if (!eventId) return;
      return rootState.profile.events[eventId];
    },

    currentEducation(state, getters, rootState) {
      if (!state.talent_education_id) return;
      return rootState.user.educations?.find(
        (n) => n.id === state.talent_education_id
      );
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    SET_PARTICIPANT_INFO(state, payload) {
      state.infoFetched = true;
      state.track = payload.track;
      state.grade = payload.grade;
      state.prefered_track = payload.prefered_track;
      state.merch_size = payload.merch_size;
      state.talent_education_id = payload.talent_education_id;
      const is4gradeUser =
        payload.track?.alias === LOW_SCHOOL_ALIAS && payload.grade === 4;
      if (!is4gradeUser) {
        state.privileges = combinePrivileges(payload.privileges);
      }

      if (payload.profiles) {
        state.profiles = payload.profiles;
      }
    },
    CHANGE_PROFILE(state, profile) {
      const idx = findIndex(state.profiles, (n) => n.id === profile.id);
      if (idx >= 0) {
        state.profiles.splice(idx, 1, profile);
      } else {
        state.profiles.push(profile);
      }
    },
    SET_SCORES(state, payload) {
      state.scores = payload;
    },
    SET_TEAM(state, payload) {
      const { profile_id, ...team } = payload;
      Vue.set(state.teams, profile_id, team);
    },
    DELETE_TEAM(state, profile_id) {
      Vue.delete(state.teams, profile_id);
    },
    UPDATE_PROFILE_STAGE(state, payload) {
      const { id } = payload;
      const idx = findIndex(state.profiles, (n) => n.id === id);
      if (idx >= 0) {
        state.profiles.splice(idx, 1, payload);
      }
    },
    SET_PROFILES_TRACKS(state, payload) {
      state.requestTrack = { ...state.requestTrack, ...payload };
    },
    PATCH_WORK(state, work) {
      const stored = state.works[work.subject_id];
      if (!stored) return;
      Vue.set(state.works, work?.subject_id, {
        ...stored,
        ...work,
      });
    },
    ADD_WORK(state, work) {
      Vue.set(state.works, work?.subject_id, work);
    },
    PATCH_PRIVILEGE(state, priv) {
      const idx = state.privileges.findIndex((n) => n.id === priv.id);
      if (idx >= 0) {
        state.privileges.splice(idx, 1, {
          ...state.privileges[idx],
          profile_id: priv.profile_id,
        });
      }
    },
  },
  actions: {
    async getInfo({ commit, state, getters, dispatch, rootState }, noCache) {
      if (state.infoFetched && !noCache) {
        return;
      }
      const { data } = await request({
        method: "GET",
        url: "/participant",
      });
      commit("SET_PARTICIPANT_INFO", data);
      /**
       * Логика для гибридного трека.
       * Для участников из 8-9 классов для командного
       * этапа нужно запросить заявки,
       * чтобы вытащить из них правильный трек
       */
      if (data.profiles && getters.isHybridTrack) {
        try {
          const userId = rootState.user.talentUser.id;
          const requestIds = getters.teamStageList.map((p) => {
            return talentRequest({
              method: "GET",
              url: `/api/users/${userId}/requests/${p.talent_event_request_id}/team-assignment-fields/${TEAM_ASSIGNMENT_FILED_TRACK}/`,
            });
          });
          const requestsData = await Promise.all(requestIds);
          // разложим треки по id заявки
          const results = requestsData.reduce((acc, response) => {
            if (response?.data?.value) {
              // значение в базе хранится ввиде строки
              // нужно убедиться что такой трек вообще существует
              const trackValue = parseInt(response.data.value, 10);
              if (rootState.tracks[trackValue]) {
                acc[response.data.request] = trackValue;
              }
            }
            return acc;
          }, {});
          commit("SET_PROFILES_TRACKS", results);
        } catch (error) {
          // ничего не делаем в случае ошибки
          console.log("error", error);
        }
      }

      /**
       * Для джуна нужно запросить мероприятия финала
       */
      if (
        data.profiles?.length &&
        getters.isJunior &&
        !getters.is4GradeJunior
      ) {
        try {
          const event = getters.selectedList?.[0]?.final_event;
          event &&
            (await dispatch("profile/getEvents", [event], { root: true }));
        } catch (error) {
          //
        }
      }

      const finalActivities = getters.activitiesByStage(FINAL_STAGE);
      if (finalActivities.length) {
        dispatch("getWorksSubjects");
      }
    },
    async getMentorInviteLink({ state, commit, rootGetters }) {
      const talent_id = rootGetters["user/user"]?.talent_id;
      if (state.inviteLink) {
        return state.inviteLink;
      }

      const { data } = await request({
        method: "GET",
        url: "mentor/code/generate",
      });

      if (data.length && talent_id) {
        const link = userInviteMentorLink(data, talent_id);
        commit("SET_STATE", {
          key: "inviteLink",
          value: link,
        });
        return link;
      }
    },
    async selectProfile({ commit, dispatch, getters, rootState }, payload) {
      const { profile_id } = payload;
      const profile = rootState.profile.profiles[profile_id];
      const userId = rootState.user.talentUser.id;
      if (!profile) {
        throw new Error(
          `Профиль ${profile_id} не найден. Пожалуйста, сообщите нам об этой ошибке.`
        );
      }
      const selected = await ProfileService.participantSelectProfile({
        profile_id,
        talentId: userId,
        final_event: payload.final_event,
        steps: profile.steps,
        stage: getters.isStudent ? TEAM_STAGE : INDIVIDUAL_STAGE,
      });

      if (selected) {
        commit("CHANGE_PROFILE", selected);
        // получим расписание по этому профилю
        try {
          await dispatch("profile/getProfileSchedule", profile_id, {
            root: true,
          });
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    async rejectProfile({ commit }, profile_id) {
      const { data } = await request({
        url: `/profiles/${profile_id}`,
        method: "DELETE",
      });
      if (data) {
        commit("CHANGE_PROFILE", data);
      }
    },
    async getMentors({ commit }) {
      const { data } = await request({
        method: "GET",
        url: "/participant/mentors",
      });
      const mentors = normalizeByKey(data.items);
      if (mentors) {
        commit("SET_STATE", {
          key: "mentors",
          value: mentors,
        });
      }
      return mentors;
    },
    async getMyAchievements({ commit, state, rootState }) {
      const userId = rootState.user.talentUser.id;
      const key = `${userId}_achievements`;
      if (state.achievements.length) return state.achievements;
      try {
        if (sessionStorage.getItem(key)) {
          const data = JSON.parse(sessionStorage.getItem(key));
          commit("SET_STATE", {
            key: "achievements",
            value: data,
          });
          return data;
        }
      } catch (error) {
        console.log("error", error);
      }
      const { data } = await talentRequest({
        method: "GET",
        url: `/api/users/${userId}/achievements/`,
        params: {
          limit: 100,
          offset: 0,
          ordering: "-created_at",
        },
      });
      const list = data.results.map(parseUserAchievement);
      commit("SET_STATE", {
        key: "achievements",
        value: list,
      });
      try {
        sessionStorage.setItem(`${userId}_achievements`, JSON.stringify(list));
      } catch (error) {
        console.log("error", error);
      }
      return data.results;
    },
    async getScores({ commit, state }) {
      if (
        Object.values(state.scores).length &&
        state.scoresFetchedAt &&
        Date.now() - state.scoresFetchedAt < 1000 * 60 * 60
      ) {
        return state.scores;
      }
      try {
        const { data } = await request({
          url: "/score",
        });
        commit("SET_SCORES", data);
        commit("SET_STATE", {
          key: "scoresFetchedAt",
          value: Date.now(),
        });
        return data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getTeam({ commit, state, getters, rootState }, profile_id) {
      if (state.teams[profile_id]) return state.teams[profile_id];
      const userId = rootState.user.talentUser.id;
      const profile = getters.teamStageList.find(
        (n) => n.profile_id === +profile_id
      );
      if (!profile?.team_id) return;
      const { data } = await talentRequest({
        method: "GET",
        url: `/api/users/${userId}/teams/${profile.team_id}/`,
      });
      commit("SET_TEAM", {
        ...data,
        profile_id: +profile_id,
      });

      return state.teams[profile_id];
    },
    /**
     * Для участников прошедших в финалы, которые имеют активности
     * нужно запросить список предметов из Сервиса для проверки работ
     */
    async getWorksSubjects({ commit, getters, dispatch, rootState }) {
      const finalActivities = getters.activitiesByStage(FINAL_STAGE);
      if (!finalActivities.length) return;

      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `${rootState.ntoToolsApiURL}/subjects/`,
          params: {
            page: 1,
            size: finalActivities.length,
            nto_activity_ids: finalActivities
              .map((n) => Number(n.id))
              .join(","),
          },
        });
        if (data.items) {
          commit("SET_STATE", {
            key: "worksSubjects",
            value: normalizeByKey(data.items, "nto_activity_id"),
          });
          dispatch("getWorks");
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    /**
     * Получить все работы пользователя по
     * предметам финала
     * @param {*} context
     * @param {Number[]} subjectIds
     */
    async getWorks({ state, commit, rootState }) {
      const list = Object.values(state.worksSubjects);
      if (!list.length) return;
      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `${rootState.ntoToolsApiURL}/works/`,
          params: {
            page: 1,
            size: 100,
            subjects: list.map((n) => n.id).join(","),
            student_talent_id: rootState.user.user.talent_id,
          },
        });

        if (data.items) {
          commit("SET_STATE", {
            key: "works",
            value: normalizeByKey(data.items, "subject_id"),
          });
        }
      } catch (error) {
        // Здесь мы ничего поделать не можем
      }
    },
    /**
     *
     * @param {*} context
     * @param {object} data
     * @param {number?} data.talent_education_id
     * @param {string?} data.merch_size
     * @returns
     */
    async patchParticipant({ commit }, data = {}) {
      // merch_size, talent_education_id
      const { merch_size, talent_education_id } = data;
      if (!merch_size && !talent_education_id) return;
      const payload = {};
      if (merch_size) payload.merch_size = merch_size;
      if (talent_education_id)
        payload.talent_education_id = talent_education_id;
      await request({
        method: "PATCH",
        url: "/participant",
        data: payload,
      });

      Object.keys(payload).forEach((key) => {
        commit("participant/SET_STATE", {
          key,
          value: payload[key],
        });
      });
    },

    async getPartners({ commit, state }) {
      if (state.partners?.length) {
        return state.partners;
      }
      const { data } = await request({
        method: "GET",
        url: "/participant/partners",
      });
      if (data?.items.length) {
        commit("SET_STATE", {
          key: "partners",
          value: data.items,
        });
      }
    },
    async getFinalAchievements({ getters, rootState, commit, state }) {
      const profiles = getters.finalStageList;

      // если нет профилей, или уже запрашивали
      if (!profiles.length || Object.values(state.finalAchievements).length)
        return;
      let eventsIds = [];
      const profileEventMap = {};
      if (getters.isJunior) {
        /**
         * Для джунов нужно запросить по всем ивентам
         * финалов, ачивку
         */
        eventsIds = rootState.profile.profiles[
          profiles[0].profile_id
        ]?.steps?.reduce((acc, step) => {
          if (step.stage === FINAL_STAGE && step.talent_event_id) {
            acc.push(step.talent_event_id);
          }
          return acc;
        }, []);
        profileEventMap[profiles[0].profile_id] = eventsIds;
      } else {
        eventsIds = profiles.reduce((acc, profile) => {
          const steps = rootState.profile.profiles[profile.profile_id]?.steps;
          if (steps.length) {
            const events = steps
              .filter((n) => {
                return n.stage === FINAL_STAGE && n.talent_event_id;
              })
              .map((n) => n.talent_event_id);
            if (events.length) {
              acc = [...acc, ...events];
            }
            profileEventMap[profile.profile_id] = events;
          }
          return acc;
        }, []);
      }

      if (!eventsIds?.length) return;

      const { data } = await calconApiRequest({
        method: "GET",
        url: `/api/users/me/achievements/`,
        params: {
          events: eventsIds.join(","),
          limit: 100,
          offset: 0,
        },
      });

      const result = data.results.reduce((acc, n) => {
        const profile = Object.entries(profileEventMap).find((pair) => {
          return pair[1].includes(n.event.id);
        });

        if (profile) {
          if (!acc[profile[0]]) {
            acc[profile[0]] = [];
          }
          acc[profile[0]].push(mapCalconAchievement(n));
        }
        return acc;
      }, {});
      commit("SET_STATE", {
        key: "finalAchievements",
        value: result,
      });
    },

    async getEduBlocks({ state, commit }) {
      if (state.edublocks?.length) return;
      const { data } = await getEducationBlocks({
        limit: 40,
        offset: 0,
        role: PARTICIPANT_ROLE,
        without_profile: true,
        t_id: state.track?.id,
      });
      commit("SET_STATE", {
        key: "edublocks",
        value: data || [],
      });
    },
  },
};
